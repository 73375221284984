import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import Images from '@components/Images';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData } from '@utils/extractors';
import { ENUM_STATIC_MENU_LINKS } from '@utils/types';

interface IDrivenByTechProps {
  data: {
    prismic: {
      allDriven_by_techs: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const DrivenByTech: React.FC<IDrivenByTechProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allDriven_by_techs');
  if (!doc) return null;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  return (
    <Layout
      title={page_title ? RichText.asText(page_title) : 'Driven By Tech'}
      description={page_description && RichText.asText(page_description)}
      firstLevelPage
      seo={{
        path: ENUM_STATIC_MENU_LINKS['DRIVEN BY TECH'],
        canonical: canonical_tag && canonical_tag.url,
        image: page_og_image ? page_og_image : null,
      }}
    >
      <div className="company">
        <div className="company__text company__text--second">
          <div className="anim-title">
            <h1>{RichText.asText(doc.node.heading)}</h1>
          </div>
          <div className="anim-block">
            {RichText.render(doc.node.body_text, linkResolver)}
          </div>
        </div>
        <div className="company__images company__images--first">
          <Images
            imageData={doc.node.images}
            extractPath="image"
            alt=""
            classes={{
              base: 'anim-image',
              extend: 'company',
              wrap: 'company__image',
              class: ''
            }}
            width="526"
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query DrivenByTech {
    prismic {
      allDriven_by_techs {
        edges {
          node {
            heading
            body_text
            images {
              image
            }
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DrivenByTech;
